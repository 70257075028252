<template>
  <div class="Tests">
    <span class="PageHeader">Администрирование</span>

    <span class="FiltersHeader">Пользователи</span>

    <div class="Filters">
      <div class="FiltersBlock noselect">
        <div class="SortBy">
          <!-- <select class="SelectInput" v-model="Filters.SortBy">
            <option
              v-for="(f, findx) in SortsList"
              :key="f + findx"
              :value="f"
              >{{ f }}</option
            >
          </select> -->

          <select class="SelectInput" v-model="Filters.Direction">
            <option
              v-for="(f, findx) in DirectionsList"
              :key="f + findx"
              :value="f"
              >{{ f }}</option
            >
          </select>

          <span class="ResetFilters" @click="clearFilters"
            >Сбросить фильтры</span
          >
        </div>
      </div>

      <input
        class="SearchInput"
        type="text"
        v-model="Filters.SearchInput"
        placeholder="Например: Иванов Иван"
      />
    </div>

    <div class="ReqCards" :key="`SearchQuery-${getSearchText}`">
      <router-link
        v-for="(rcard, rcindx) in getSortedList"
        :class="{
          Card: true,
        }"
        :key="rcindx + rcard"
        :to="`/admin/sprints/step/2?user_id=${rcard.id}`"
      >
        <span class="CDate">{{ getSections(rcard?.LatestJob?.sections) }}</span>
        <span class="CFIO"
          >{{ getFIO(rcard.surname, rcard.name, rcard.patronymic) }}
        </span>
        <span class="CInfo">{{ getShortDesc(rcard?.MapRegion) }}</span>

        <div class="StatusAndDate">
          <span :class="getStep1CardStatus(rcard).c">{{
            getStep1CardStatus(rcard).t
          }}</span>

          <span class="CDateOnRight">{{
            getHumanDate(rcard?.LatestJob?.JobSprints?.[0]?.createdAt)
          }}</span>
        </div>
        <!-- <span
            :class="{
              CStatus: true,
              IsWaiting: rcard?.status === 'Ожидает рассмотрения',
              IsAccepted: rcard?.status === 'Принята',
              IsDeclined:
                rcard?.status === 'Отклонена' ||
                rcard?.status === 'Отменена пользователем',
            }"
            >{{ rcard?.status }}</span
          > -->
      </router-link>
    </div>
  </div>
</template>

<script>
import {
  adminGetAllUsersSprints,
  createSprintOnMonday,
  checkSprint,
  updateSprint,
} from "@/api/sprints.js";

import { QuillEditor } from "@vueup/vue-quill";

import { getSectorsWithSections } from "@/api/competence.js";

import ButtonGradient from "@/components/CustomElements/ButtonGradient.vue";

import Fuse from "fuse.js";

export default {
  components: { ButtonGradient, QuillEditor },
  data() {
    return {
      Statuses: [
        "Ожидает рассмотрения",
        "Принята",
        "Отклонена",
        "Отменена пользователем",
      ],
      SortsList: [
        "Сортировка по умолчанию (Новее)",
        "По дате (Старее)",
        "По региону (А-я)",
        "По региону (Я-а)",
        "По вузу (А-я)",
        "По вузу (Я-а)",
        "По cтатусу (При-Ожи-Отк)",
        "По cтатусу (Отк-Ожи-При)",
      ],
      DirectionsList: [],
      Filters: {
        SortBy: "Сортировка по умолчанию (Новее)",
        SearchInput: "",
        Direction: "Выберите направление",
      },

      UsersWithData: [],
      ProjectsList: [],
      JobsList: [],
      SelectedJobWithSprints: null,
      SelectedSprint: null,
      SelectedUser: null,

      SelectedReq: null,
      ReqStep: 1,

      levels: {
        Легкий: 1,
        Средний: 2,
        Сложный: 3,
      },

      showModal2: false,

      showModal: false,
      showModalStep: 1,
      showModalComment: "",

      SearchOptions: {
        isCaseSensitive: false,
        includeScore: false,
        shouldSort: false,
        threshold: 0.25,
        keys: ["name", "surname", "patronymic"],
      },
    };
  },
  async created() {
    let _directions_list_resp = await getSectorsWithSections();
    this.DirectionsList = _directions_list_resp.data.map((x) => x.sections);
    this.DirectionsList = [].concat(...this.DirectionsList);
    this.DirectionsList = this.DirectionsList.map((x) => x.name.toUpperCase());
    this.DirectionsList.unshift("Выберите направление");

    await this.initialization();
  },
  watch: {},
  computed: {
    getFilteredList() {
      let list = [...this.UsersWithData];

      if (this.Filters.Direction !== "Выберите направление") {
        list = list.filter((x) => {
          return this.getSectionsList(x?.LatestJob?.sections)
            .map((x) => x.toUpperCase())
            .includes(this.Filters.Direction);
        });
      }

      if (this.getSearchText == "") {
        return list;
      }

      // Инициализация экземпляра fuse
      const fuse = new Fuse(list, this.SearchOptions);

      // Выполнение поиска
      list = fuse.search(this.getSearchText);
      list = list.map((x) => x.item);

      return list;
    },

    getSearchText() {
      return this.Filters.SearchInput;
    },

    getSortedList() {
      let list = [...this.getFilteredList];
      return list;

      if (this.Filters.SortBy === "Сортировка по умолчанию (Новее)") {
        list = list.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);

          return dateB - dateA; // Старые даты будут впереди
        });

        return list;
      }

      if (this.Filters.SortBy === "По дате (Старее)") {
        list = list.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);

          return dateA - dateB; // Старые даты будут впереди
        });

        return list;
      }
      if (this.Filters.SortBy === "По дате (Старее)") {
        list = list.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);

          return dateA - dateB; // Старые даты будут впереди
        });

        return list;
      }

      if (this.Filters.SortBy === "По региону (А-я)") {
        list = list.sort((a, b) => {
          const cityA = this.getCity(a?.user_data?.MapRegion).toLowerCase();
          const cityB = this.getCity(b?.user_data?.MapRegion).toLowerCase();

          const firstCharA = cityA.charCodeAt(0);
          const firstCharB = cityB.charCodeAt(0);

          return firstCharA - firstCharB;
        });

        return list;
      }
      if (this.Filters.SortBy === "По региону (Я-а)") {
        list = list.sort((a, b) => {
          const cityA = this.getCity(a?.user_data?.MapRegion).toLowerCase();
          const cityB = this.getCity(b?.user_data?.MapRegion).toLowerCase();

          const firstCharA = cityA.charCodeAt(0);
          const firstCharB = cityB.charCodeAt(0);

          return firstCharB - firstCharA;
        });

        return list;
      }

      if (this.Filters.SortBy === "По вузу (А-я)") {
        list = list.sort((a, b) => {
          const univerA = this.getUniver(a?.user_data?.MapRegion).toLowerCase();
          const univerB = this.getUniver(b?.user_data?.MapRegion).toLowerCase();

          const firstCharA = univerA.charCodeAt(0);
          const firstCharB = univerB.charCodeAt(0);

          return firstCharA - firstCharB;
        });

        return list;
      }
      if (this.Filters.SortBy === "По вузу (Я-а)") {
        list = list.sort((a, b) => {
          const univerA = this.getUniver(a?.user_data?.MapRegion).toLowerCase();
          const univerB = this.getUniver(b?.user_data?.MapRegion).toLowerCase();

          const firstCharA = univerA.charCodeAt(0);
          const firstCharB = univerB.charCodeAt(0);

          return firstCharB - firstCharA;
        });

        return list;
      }

      if (this.Filters.SortBy === "По cтатусу (При-Ожи-Отк)") {
        const statusOrder = {
          Принята: 1,
          "Ожидает рассмотрения": 2,
          Отклонена: 3,
          "Отменена пользователем": 3,
        };

        list = list.sort(
          (a, b) => statusOrder[a.status] - statusOrder[b.status]
        );

        return list;
      }
      if (this.Filters.SortBy === "По cтатусу (Отк-Ожи-При)") {
        const statusOrder = {
          Принята: 3,
          "Ожидает рассмотрения": 2,
          Отклонена: 1,
          "Отменена пользователем": 1,
        };

        list = list.sort(
          (a, b) => statusOrder[a.status] - statusOrder[b.status]
        );

        return list;
      }

      return list;
    },
  },
  methods: {
    getSectionsList(sects) {
      if (sects == null) {
        return "";
      }

      return sects.map((x) => x.name);
    },
    getSections(sects) {
      if (sects == null) {
        return "";
      }

      return sects.map((x) => x.name).join(", ");
    },
    handleEditorEditChanges(delta) {
      if (this.SelectedSprint != null) {
        this.SelectedSprint.sprint_task_desc = this.$refs.QuillEditor.getHTML();
        this.updateSprintInfo();
      }
    },
    handleEditorEditReady() {
      if (this.SelectedSprint != null) {
        this.$refs.QuillEditor.setHTML(this.SelectedSprint.sprint_task_desc);
      }
    },

    handleEditorEditChangesComment(delta) {
      if (this.showModalComment != null) {
        this.showModalComment = this.$refs.QuillEditor.getHTML();
        this.updateSprintInfo();
      }
    },
    handleEditorEditReadyComment() {
      if (this.showModalComment != null) {
        this.$refs.QuillEditor.setHTML(this.showModalComment);
      }
    },

    async updateSprintInfo() {
      await updateSprint({
        id: this.SelectedSprint.id,
        sprint_task_name: this.SelectedSprint.sprint_task_name,
        sprint_task_desc: this.SelectedSprint.sprint_task_desc,
        new_start_date: this.SelectedSprint.sprint_start_copy,
        new_end_date: this.SelectedSprint.sprint_end_copy,
      });
    },

    async initialization() {
      let _jobReqList = await adminGetAllUsersSprints();
      let rawList = _jobReqList.data;

      rawList = rawList.map((x) => {
        let _jobUser = [...x?.JobUser];

        // Создаем объект для группировки по project.id или n_project.id
        let groupedData = _jobUser.reduce((acc, obj) => {
          let id = null;
          if (obj?.project?.id != null) {
            id = `p${obj?.project?.id}`;
          } else if (obj?.n_project?.id != null) {
            id = `np${obj?.project?.id}`;
          } else {
            id = "nullproject";
          }

          if (!acc[id]) {
            acc[id] = [];
          }
          acc[id].push(obj);
          return acc;
        }, {});

        // Преобразуем объект обратно в массив
        const groupedArray = Object.values(groupedData);

        let LatestJob = { ...x?.JobUser[0] };

        return {
          id: x.id,
          name: x.name,
          surname: x.surname,
          patronymic: x.patronymic,
          MapRegion: x.MapRegion,
          Projects: groupedArray,
          LatestJob: LatestJob,
        };
      });

      this.UsersWithData = rawList;

      if (this.SelectedUser != null) {
        this.ProjectsList = this.UsersWithData.find(
          (x) => x.id === this.SelectedUser
        );

        if (
          this.JobsList != null &&
          this.JobsList.length != 0 &&
          this.ProjectsList != null
        ) {
          this.JobsList = this.ProjectsList.find((x) => {
            let a = x.find((job) => job.id === this.JobsList?.[0]?.id);
            return a != null;
          });

          if (this.SelectedJobWithSprints != null) {
            this.SelectedJobWithSprints = this.JobsList.find(
              (x) => x.id === this.SelectedJobWithSprints.id
            );

            if (this.SelectedSprint != null) {
              this.SelectedSprint = this.SelectedJobWithSprints?.JobSprints?.find(
                (x) => x.id === this.SelectedSprint.id
              );
            }
          }
        }
      }
    },

    async createSprint() {
      let res = await createSprintOnMonday({
        jobID: this.SelectedJobWithSprints.id,
        userID: this.SelectedSprint.sprint_user_id,
      });

      this.initialization();
      this.showModal = false;
    },
    async checkSprint(isCheck) {
      let res = await checkSprint({
        cur_sprint_id: this.SelectedSprint.id,
        comment: isCheck
          ? this.showModalComment
          : "Не выполнил спринт, не загрузил материалы",
        job_is_done: !isCheck,
      });

      this.initialization();
      this.showModal = false;
    },
    async closeTask(isSuccess) {
      let res = await checkSprint({
        cur_sprint_id: this.SelectedSprint.id,
        comment: isSuccess
          ? "Задача была успешно выполнена"
          : "Не выполнил спринт, не загрузил материалы",
        job_is_done: true,
      });

      this.initialization();
      this.showModal2 = false;
    },

    getSprintStatus(sprint) {
      if (
        sprint?.status == "Не проверено" &&
        sprint?.SprintMaterials?.length == 0
      ) {
        return { status: "failed", text: "Спринт не выполнен" };
      }

      if (
        sprint?.status == "Не проверено" &&
        sprint?.SprintMaterials?.length > 0
      ) {
        return { status: "awaiting", text: "Ожидает проверки" };
      }

      if (
        sprint?.status != "Не проверено" &&
        sprint?.SprintMaterials?.length > 0
      ) {
        return { status: "done", text: "Проверено" };
      }

      return { status: "", text: "" };
    },

    getSprintsSorted(Sprints) {
      if (Sprints == null) {
        return [];
      }

      return Sprints.sort((a, b) => b.id - a.id);
    },

    getFilename(mat) {
      const filePath = mat.url_file;

      // Функция для извлечения имени файла с расширением без чисел до символа "-"
      let getCleanFileNameWithExtension = (filePath) => {
        const parts = filePath.split(/[\\\/]/);
        const fileNameWithExtension = parts[parts.length - 1];
        const fileNameWithoutNumbersBeforeDash = fileNameWithExtension.replace(
          /^\d+-/,
          ""
        );
        return fileNameWithoutNumbersBeforeDash;
      };

      const cleanFileNameWithExtension = getCleanFileNameWithExtension(
        filePath
      );

      return cleanFileNameWithExtension;
    },
    sprintIsActual(date_end) {
      if (date_end == null) {
        return false;
      }

      const specifiedDate = new Date(date_end); // Замените "2022-01-01" на вашу заданную дату
      // Получаем текущую дату
      const currentDate = new Date();

      console.log(specifiedDate < currentDate);

      return currentDate < specifiedDate;
    },

    getStep1CardStatus(rcard) {
      let findAwaitingStatus = rcard?.Projects.find((p) => {
        let j = p.find((x) => x?.jobs_worker?.status === "В работе");
        return j != null;
      });

      for (let ProjArray of rcard?.Projects) {
        for (let Job of ProjArray) {
          let sortedSprints = Job.JobSprints.sort((a, b) => b.id - a.id);
          if (!this.sprintIsActual(sortedSprints?.[0]?.sprint_end)) {
            if (
              sortedSprints?.[0]?.SprintMaterials?.length == 0 &&
              Job.jobs_worker?.status === "В работе"
            ) {
              return { c: "CStatus IsDeclined", t: "Спринт просрочен" };
            }
          }

          for (let Sprint of Job.JobSprints) {
            if (
              Sprint?.status === "Не проверено" &&
              Sprint?.SprintMaterials?.length > 0
            ) {
              return { t: "Ожидает проверки", c: "CStatus IsAccepted" };
            }
          }
        }
      }

      if (findAwaitingStatus != null) {
        return {
          t: "В работе",
          c: "CStatus IsWaiting",
        };
      }

      return {
        t: "Работа завершена",
        c: "CStatus IsDone",
      };
    },

    getStep1Status(sprint) {
      if (
        sprint?.SprintMaterials != null &&
        sprint?.SprintMaterials?.length != 0 &&
        sprint?.status == "Не проверено"
      ) {
        return {
          done: false,
          text: "Ожидает проверки",
        };
      }

      // Создаем объект с заданной датой
      const specifiedDate = new Date(sprint?.sprint_end); // Замените "2022-01-01" на вашу заданную дату
      // Получаем текущую дату
      const currentDate = new Date();

      if (
        (sprint?.SprintMaterials == null ||
          sprint?.SprintMaterials?.length == 0) &&
        specifiedDate < currentDate &&
        sprint?.job_is_done
      ) {
        return {
          done: false,
          text: "Выполнение завершено досрочно",
        };
      }

      if (
        (sprint?.SprintMaterials == null ||
          sprint?.SprintMaterials?.length == 0) &&
        specifiedDate < currentDate &&
        !sprint?.job_is_done
      ) {
        return {
          done: false,
          text: "Спринт не открыт",
        };
      }

      if (sprint?.job_is_done) {
        return {
          done: true,
          text: `Завершено ${this.getHumanDate(sprint?.updatedAt)}`,
        };
      }

      return {
        done: false,
        text: "В работе",
      };
    },

    getCity(MapRegion) {
      let City = "";

      let MapUniver = MapRegion;

      if (MapUniver != null && MapUniver?.UsersChoosedUniver != null) {
        City = MapUniver.UsersChoosedUniver.region.name;
        City = City == "Другое" ? MapUniver.region_other : City;
      }

      return City;
    },
    getUniver(MapRegion) {
      let Univer = "";

      let MapUniver = MapRegion;

      if (MapUniver != null && MapUniver?.UsersChoosedUniver != null) {
        Univer = MapUniver.UsersChoosedUniver.name;
        Univer = Univer == "Другое" ? MapUniver.univer_other : Univer;
      }

      return Univer;
    },

    getNormalDate(BDDate) {
      let date = BDDate.split("T")[0].split("-");
      return `${date[2]}.${date[1]}.${date[0]}`;
    },
    getFIO(F, I, O) {
      if (F == null) {
        F = "";
      }
      if (I == null) {
        I = "";
      }
      if (O == null) {
        O = "";
      }
      return `${F} ${I} ${O}`;
    },
    getShortDesc(MapRegion) {
      let Univer = "";
      let City = "";

      let MapUniver = MapRegion;

      if (MapUniver != null && MapUniver?.UsersChoosedUniver != null) {
        City = MapUniver.UsersChoosedUniver.region.name;
        City = City == "Другое" ? MapUniver.region_other : City;

        Univer = MapUniver.UsersChoosedUniver.name;
        Univer = Univer == "Другое" ? MapUniver.univer_other : Univer;
      }

      let UniverCityRes = [Univer, City];
      UniverCityRes = UniverCityRes.filter((x) => x != null && x != "");

      let pre_res = UniverCityRes.join(", ");

      return pre_res == null || pre_res == "" ? "Не указано" : pre_res;
    },
    getFullDesc(user_data) {
      let Univer = "";
      let City = "";

      let MapUniver = user_data?.MapRegion;

      if (MapUniver != null && MapUniver?.UsersChoosedUniver != null) {
        City = MapUniver.UsersChoosedUniver.region.name;
        City = City == "Другое" ? MapUniver.region_other : City;

        Univer = MapUniver.UsersChoosedUniver.name;
        Univer = Univer == "Другое" ? MapUniver.univer_other : Univer;
      }

      let UniverCityRes = [Univer, City, user_data?.tel];
      UniverCityRes = UniverCityRes.filter((x) => x != null && x != "");

      return UniverCityRes.join(", ");
    },

    getFullJob(job_data) {
      return `${job_data?.name}`;
    },
    getFullJobDesc(job_data) {
      let JobDesc = [
        job_data?.section.name,
        job_data?.repeatable ? "типовая" : "",
      ];
      JobDesc = JobDesc.filter((x) => x != null && x != "");
      return JobDesc.join(", ");
    },

    getHumanDate(date_start) {
      if (date_start == null || date_start == "") {
        return "";
      }

      let Date = date_start.split("T")[0].split("-");
      return `${Date[2]}.${Date[1]}.${Date[0]}`;
    },
    getHumanDateWithTime(date_end) {
      if (date_end == null || date_end == "") {
        return "";
      }

      let Date = date_end.split("T")[0].split("-");
      let Time = date_end.split("T")[1].split(":");
      return `${Date[2]}.${Date[1]}.${Date[0]} ${Time[0]}:${Time[1]}`;
    },

    getProjectName(p) {
      if (p?.n_project != null) {
        return p?.n_project?.name;
      } else if (p?.project != null) {
        return p?.project?.name;
      } else {
        return `Проект(-ы) удалены`;
      }
    },
    getProjectSections(list) {
      let res = list
        .map((x) => {
          return x?.section?.name == null ? null : x?.section?.name;
        })
        .filter((x) => x != null);

      if (res.length != 0) {
        return res.join(", ");
      } else {
        return "Не указано/Утеряно";
      }
    },

    clearFilters() {
      this.Filters = {
        SortBy: "Сортировка по умолчанию (Новее)",
        SearchInput: "",
        Direction: "Выберите направление",
      };
    },
  },
};
</script>

<style scoped>
@import "./../../../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

.PCardHeader {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: fit-content;
  gap: 20px;
}
.PCardHeader > * {
  margin-top: auto;
  margin-bottom: auto;
}

.TextStyled {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  padding: 8px;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  padding: 8px;
  border-radius: 4px;
  border: 1px solid #d3caff40;

  background-color: transparent;
}

.MSBody {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  width: 100%;
  height: fit-content;
}
.BlockWithSmallGap {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: fit-content;
}
.SprintTitle {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  color: #656bff;
  text-decoration: underline;
}
.MSTitle {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #ffffffe5;
}
.MSText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #d3caffb2;
}
.MSMaterials {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  width: 100%;
  height: fit-content;
}
.MSMaterials > a {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #656bff;
}
.MSTextArea {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #ffffffe5;

  background-color: transparent;
}

.sprints-list {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  padding-left: 22px;

  width: 100%;
  height: fit-content;
}
.sprint-block {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: fit-content;

  z-index: 2;
}
.sprint-block-title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sprint-block-title-bold {
  font-weight: 700;
}

.sprints-vertical-line {
  position: absolute;
  display: block;

  padding-top: 5px;

  left: 5px;
  bottom: 0;

  width: 1px;
  height: calc(100% - 4px);

  background-color: #514ebe;

  z-index: 1;
}

.sprint-block-card {
  position: relative;
  display: flex;

  cursor: pointer;

  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  padding: 20px 30px;

  width: 100%;
  height: fit-content;

  border-radius: 20px;
  background: #1e1c42;
}

.sprint-vertical-line-circle {
  position: absolute;
  display: block;

  top: 3px;
  left: -22px;
  width: 11px;
  height: 11px;

  background: white;
  border-radius: 100%;
}

.sprint-card-desc > * {
  margin-top: auto;
  margin-bottom: auto;
}
.sprint-card-desc {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  width: 100%;
  height: fit-content;
}

.sprint-card-desc-text {
  position: relative;
  display: block;
  width: 100%;
  height: fit-content;
  color: #fff;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  flex-shrink: 1;
  flex-grow: 0;
  text-align: left;
}
.sprint-arrow-right {
  position: relative;
  width: 21px;
  height: auto;

  cursor: pointer;

  aspect-ratio: 1 / 0.7619;
}

.sprint-card-status {
  position: relative;
  display: block;

  padding: 4px 8px;

  width: fit-content;
  height: fit-content;

  color: #1dd185;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 4px;
  border: 1px solid #1dd185;
}

.CustomButtonDecline {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  background-color: #656bff40;

  flex-shrink: 0;
}

.ModalWrapper {
  position: fixed;

  top: 0;
  left: 0;

  display: flex;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.75);

  z-index: 500;
}

.SendRequestModal {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 14px;

  width: fit-content;
  min-width: 464px;
  height: fit-content;

  max-width: 100%;
  max-height: 98%;

  overflow-y: auto;

  padding: 20px;
  margin: auto;

  background: linear-gradient(
    224.99deg,
    #1e1b4a 0.05%,
    #1f1c4a 48.65%,
    #31337b 100.05%
  );
  border-radius: 8px;

  z-index: 10;

  scrollbar-width: thin;
  scrollbar-color: #413e7400 transparent;
}
.SendRequestModal::-webkit-scrollbar {
  width: 0px;
}
.SendRequestModal::-webkit-scrollbar-track {
  background: transparent;
}
.SendRequestModal::-webkit-scrollbar-thumb {
  background-color: #413e7400;
  border-radius: 0px;
  border: 0px solid transparent;
}

.SendRequestModal textarea {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;
  min-height: 48px;

  padding: 12px 8px;
  border-radius: 4px;
  border: 1px solid #d3caff40;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  background-color: transparent;
}

.Close {
  position: absolute;
  display: block;

  top: 0;
  right: 0;

  width: 32px;
  height: 32px;

  background: url("./../../../assets/img/Landing/CloseButtonForRegModal.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

  border-radius: 4px;

  background-color: #656bff40;

  z-index: 10;
}
.Title {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  z-index: 5;
}
.Title > span {
  color: #fff;

  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-right: 195px;
}

.ReqInfo {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}
.RTop,
.RBot {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  width: 100%;
  height: fit-content;
}
.RFIO {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #ffffffe5;
}
.RInfo {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #d3caffb2;
}
.RInfo > a {
  position: relative;
  display: block;

  padding-left: 4px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  text-decoration: underline;
  color: #656bff;
}
.RBot > span:nth-child(1) {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  text-decoration: underline;
  color: #656bff;
}
.RBot > span:nth-child(2) {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #ffffffd4;
  max-width: 500px;
}
.RBot > span:nth-child(3) {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #d3caffb2;
}

.ReqButtons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: fit-content;
}
</style>

<style scoped>
.ProjectDifficult {
  position: relative;
  margin-left: 3.5px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
}
.ProjectDifficult > span:not(:nth-last-child(1)) {
  margin-right: 3px;
}
.ProjectDifficult > span {
  margin-top: auto;
  margin-bottom: auto;
}

.DifficultPainted {
  position: relative;
  display: block;
  width: 10px;
  height: 20px;
  background-image: url("./../../../assets/img/profile/ProfileProjectCard/DifficultIcon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.DifficultEmpty {
  position: relative;
  display: block;
  width: 10px;
  height: 20px;
  background-image: url("./../../../assets/img/profile/ProfileProjectCard/DifficultIconPlaceholder.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.PLongCard {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  width: 100%;
  height: fit-content;

  padding: 20px 30px;
  border-radius: 16px;

  background-color: #1e1b4a;
}
.PLHead {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.PLDesc {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  line-height: 23.25px;
  text-align: left;

  color: #c8c3de;
}
.PLTips {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}

.ButtonBack {
  position: relative;
  display: block;

  cursor: pointer;

  width: fit-content;
  min-width: 250px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;

  padding: 12px 16px;
  border-radius: 4px;

  background-color: #656bff;
}

.Card {
  position: relative;
  display: flex;

  width: 23.5%;

  height: auto;

  flex-basis: auto;

  padding: 20px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  transition: all 0.2s ease-out;

  border-radius: 12px;

  background: url("./../../../assets/img/Admin/LogoBG.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #1e1b4a;
}
.Card:hover {
  cursor: pointer;
  background: none;
  background-color: #27235c;
}
.Card.Selected {
  background: none;
  background-color: #27235c;
}
.CDate {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  margin-bottom: 4px;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  text-transform: uppercase;

  color: #d3caff4d;
}
.CFIO {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #fff;
}
.PFIO {
  position: relative;
  display: block;

  margin-bottom: auto;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #fff;
}
.CInfo {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #d3caffb2;
}
.CStatus,
.PStatus {
  position: relative;
  display: block;

  padding: 4px 8px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  border-radius: 4px;
}
.PStatus {
  margin-top: 52px;
}

.StatusAndDate {
  position: relative;
  display: flex;

  margin-top: 52px;

  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;

  width: 100%;
  height: fit-content;
}
.StatusAndDate > * {
  margin-top: auto;
  margin-bottom: auto;
}

.CDateOnRight {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #d3caff4d;
}

.IsWaiting {
  color: #f9dc5c;
  background-color: #f9dc5c26;
  border: 1px solid #f9dc5c26;
}
.IsAccepted {
  color: #25ed69;
  background-color: #25ed6926;
  border: 1px solid #25ed6926;
}
.IsDeclined {
  color: #ed254e;
  background-color: #ed254e26;
  border: 1px solid #ed254e26;
}

.IsDone {
  color: #d3caffb2;
  background-color: #27235c;
  border: 1px solid #27235c;
}

.ReqCards {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px 2%;
}

.Tests {
  position: relative;

  padding-top: 60px;
  padding-left: 156px;
  padding-right: 156px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  padding: 50px 8vw;

  width: 100%;
  min-height: 100vh;

  color: white;
}

.PageHeader {
  position: relative;
  display: block;

  margin-bottom: 20px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
}
.FiltersHeader {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.Filters {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  gap: 40px;

  width: 100%;
  height: fit-content;
}
.FiltersBlock {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  width: auto;
  height: fit-content;
}
.StatusesBlock,
.SortBy {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  width: auto;
  height: fit-content;
}
.StatusesBlock > span {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  border: 1px solid #656bff;
  background-color: transparent;
  transition: all 0.2s ease-out;
}
.StatusesBlock > span.selected,
.StatusesBlock > span:hover {
  background-color: #656bff;
  border: 1px solid transparent;
}

.SearchInput {
  position: relative;
  display: block;

  padding: 0.5em 1em 0.5em 1em;

  width: 378px;
  height: fit-content;

  border-radius: 4px;
  border: 1px solid #d3caff40;

  background-color: transparent;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #fff;
}
.SearchInput::placeholder,
.SearchInput::-ms-input-placeholder {
  color: #d3caff73;
}

.SelectInput {
  position: relative;
  display: inline-block;

  cursor: pointer;

  padding: 0.5em 3.5em 0.5em 1em;
  padding-right: 45px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  border: 1px solid #656bff;
  background-color: transparent;
  transition: all 0.2s ease-out;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    radial-gradient(#ddd 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}
.SelectInput::-ms-expand {
  display: none;
}
.SelectInput:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    radial-gradient(gray 70%, transparent 72%);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  outline: 0;
}
.SelectInput:hover {
  background-color: #656bff;
  border: 1px solid transparent;
}

.ResetFilters {
  position: relative;
  display: inline-block;

  cursor: pointer;

  padding: 0.5em 1em 0.5em 1em;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  border: 1px solid transparent;
  background-color: #656bff;
  transition: all 0.2s ease-out;
}
.ResetFilters:hover {
  background-color: transparent;
  border: 1px solid #656bff;
}
</style>
